import { Close, Menu } from "@mui/icons-material";
import { IconButton, SwipeableDrawer, Typography, Box, FormControl, Select, MenuItem, Switch, FormControlLabel } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { createUseStyles } from "react-jss";
import Copyright from "./Data/Copyright";
import Ind from "../Assets/flag/indonesia.png";
import Eng from "../Assets/flag/united-kingdom.png";
import Jap from "../Assets/flag/japan.png";
import { useRecoilState } from "recoil";
import { darkTheme, language } from "../store";

const useStyles = createUseStyles({
  btn: {
    transition: "0.5s",
    textDecoration: "none",
    letterSpacing: "3px",
    "&:hover": {
      color: "black",
      letterSpacing: "5px",
    },
  },
});
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#111111',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));
export default function MobileBar() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const [lang, setLang] = useRecoilState(language);
  const langChange = (e) => {
    setLang(e.target.value)
  }
  const [isDarkTheme, setDarkTheme] = useRecoilState(darkTheme);
  const toggleTheme = () => {
    setDarkTheme(!isDarkTheme);
  }
  const menusEng = [
    { name: "Home", link: "/", value: "home" },
    { name: "About", link: "/about", value: "about" },
    { name: "Portfolio", link: "/portfolio", value: "portfolio" },
    { name: "Contact", link: "/contact", value: "contact" },
  ]
  const menusIdn = [
    { name: "Utama", link: "/", value: "home" },
    { name: "Tentang Saya", link: "/about", value: "about" },
    { name: "Portofolio", link: "/portfolio", value: "portfolio" },
    { name: "Kontak", link: "/contact", value: "contact" },
  ]
  const menusJap = [
    { name: "ホームページ", link: "/", value: "home" },
    { name: "私について", link: "/about", value: "about" },
    { name: "ポートフォリオ", link: "/portfolio", value: "portfolio" },
    { name: "コンタクト", link: "/contact", value: "contact" },
  ]
  return (
    <React.Fragment>
      <IconButton sx={{ color: isDarkTheme ? "#fff" : "#000" }} onClick={toggleDrawer(true)}>
        {open === false ? <Menu /> : <Close />}
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          sx: {
            backgroundColor: isDarkTheme ? "#292929" : "#fff",
          }
        }}
      >
        <Box sx={{ padding: "1rem", minWidth: "200px" }}>
          <div>
            <FormControl variant="standard">
              <Select
                value={lang}
                onChange={langChange}
                displayEmpty
                disableUnderline
                inputProps={{ 'aria-label': 'language' }}
                IconComponent={''}
              >
                <MenuItem value={'idn'}><img style={{ borderRadius: "50%", border: isDarkTheme ? "2px solid #fff" : "2px solid #000" }} width={20} height={20} src={Ind} alt="indonesia" /></MenuItem>
                <MenuItem value={'eng'}><img style={{ borderRadius: "50%", border: isDarkTheme ? "2px solid #fff" : "2px solid #000" }} width={20} height={20} src={Eng} alt="english" /></MenuItem>
                <MenuItem value={'jap'}><img style={{ borderRadius: "50%", border: isDarkTheme ? "2px solid #fff" : "2px solid #000" }} width={20} height={20} src={Jap} alt="japan" /></MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              marginTop: "5rem",
            }}
          >
            {lang === 'eng' && menusEng.map((x, i) => (
              <Typography key={i} style={{ margin: "0.5rem 0rem" }}>
                <NavLink
                  exact
                  className={classes.btn}
                  style={{
                    color: isDarkTheme ? "#fff" : "#292929",
                  }}
                  to={x.link}
                  activeStyle={{
                    fontWeight: "bold",
                    letterSpacing: "5px",
                  }}
                >
                  {x.name}
                </NavLink>
              </Typography>
            ))}
            {lang === 'idn' && menusIdn.map((x, i) => (
              <Typography key={i} style={{ margin: "0.5rem 0rem" }}>
                <NavLink
                  exact
                  className={classes.btn}
                  style={{
                    color: isDarkTheme ? "#fff" : "#292929",
                  }}
                  to={x.link}
                  activeStyle={{
                    fontWeight: "bold",
                    letterSpacing: "5px",
                  }}
                >
                  {x.name}
                </NavLink>
              </Typography>
            ))}
            {lang === 'jap' && menusJap.map((x, i) => (
              <Typography key={i} style={{ margin: "0.5rem 0rem" }}>
                <NavLink
                  exact
                  className={classes.btn}
                  style={{
                    color: isDarkTheme ? "#fff" : "#292929",
                  }}
                  to={x.link}
                  activeStyle={{
                    fontWeight: "bold",
                    letterSpacing: "5px",
                  }}
                >
                  {x.name}
                </NavLink>
              </Typography>
            ))}
          </div>
          <div style={{ position: 'absolute', bottom: 5, marginBottom: '3em' }}>
            <FormControlLabel
              control={<MaterialUISwitch onChange={toggleTheme} sx={{ m: 1 }} />}
              label=""
            />
          </div>
          <div style={{ position: "absolute", bottom: "1rem", left: "1rem" }}>
            <Copyright />
          </div>
        </Box>
      </SwipeableDrawer>
    </React.Fragment>
  );
}
