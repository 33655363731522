export const devPortfolio = [
    {
        name: "Thermomix Admin",
        link: "https://club-beta.thermomixindonesia.co.id/login",
        img: "./thermomix.png",
        description:
            "Website for Thermomix, website for manage advisor and partner",
    },
    {
        name: "Codwell BE",
        link: "https://cbr-admin.sitebox.dev",
        img: "./codwell_be.png",
        description: "Management apps for real estate and properties",
    },
    {
        name: "Codwell FE",
        link: "https://www.coldwellbanker.com/",
        img: "./codwell_fe.png",
        description: "Management apps for real estate and properties",
    },
    {
        name: "APII BE",
        link: "https://angkasapura.sitebox.dev",
        img: "./ap_ii.png",
        description: "Management apps for booking and selling",
    },
];
