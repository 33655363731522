import React from "react";
import ReactDOM from 'react-dom';
import { ThemeProvider } from "@mui/material";
import App from "./App";
import theme from "./Theme";
import { RecoilRoot } from 'recoil';
const rootElement = document.getElementById("root")
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </ThemeProvider>
    </React.Fragment>,
    rootElement
  );
} else {
  ReactDOM.render(
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </ThemeProvider>
    </React.Fragment>,
    rootElement
  );
}
