import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { createUseStyles } from "react-jss";
import { useRecoilState } from "recoil";
import MobileBar from "../Components/MobileBar";
import Sidebar from "../Components/Sidebar";
import { darkTheme } from "../store";
import { Helmet } from 'react-helmet';

const useStyles = createUseStyles({
  sidebarContainer: {
    height: "100%",
    width: "25%",
    position: "fixed",
    top: 0,
    left: 0,
    overflow: "hidden",
    padding: "2em",
  },
  mainContainer: {
    padding: "0px 10px",
  },
});
export default function Public({ title, children }) {
  const theme = useTheme();
  const classes = useStyles();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const [isDarkTheme, setDarkTheme] = useRecoilState(darkTheme);

  return (
    <>
      {isDarkTheme ?
        <Helmet bodyAttributes={{ style: 'background-color : #404040' }} />
        :
        <Helmet bodyAttributes={{ style: 'background-color : #eee' }} />
      }
      <Grid container>
        <Grid
          item
          md={4}
          sx={{
            display: matchesMD ? "none" : "block",
            backgroundColor: isDarkTheme ? "#292929" : "#ffffff",
          }}
          className={classes.sidebarContainer}
        >
          <Sidebar />
        </Grid>
        <Grid
          item
          md={8}
          sx={{
            marginLeft: matchesMD ? 0 : "25%",
            marginTop: "2rem",
          }}
          className={classes.mainContainer}
        >
          <Container>{children}</Container>
        </Grid>
      </Grid>
      <div
        style={{
          position: "fixed",
          top: 3,
          right: 3,
          display: matchesMD ? "block" : "none",
        }}
      >
        <MobileBar />
      </div>
    </>
  );
}
