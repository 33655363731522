import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    primary: {
      dark: "#b26500",
      main: "#ff9100",
      light: "#ffa733",
      contrastText: "#ffffff",
    },
    secondary: {
      dark: "#0b0b0b",
      main: "#111111",
      light: "#404040",
      contrastText: "#ffffff",
    },
    white: {
      dark: "#afafaf",
      main: "#fafafa",
      light: "#fbfbfb",
      contrastText: "#292929",
    },
    black: {
      dark: "#0b0b0b",
      main: "#111111",
      light: "#404040",
      contrastText: "#ffffff",
    },
  },
});
