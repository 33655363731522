import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography, Link, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil';
import { devPortfolio } from '../../Components/Data/DevPortfolio'
import { darkTheme, language } from '../../store';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-206605434-2');

export default function WebApps() {
    const [lang, setLang] = useRecoilState(language);
    const [isDarkTheme, setDarkTheme] = useRecoilState(darkTheme);
    const [mounted, setMounted] = useState(false)
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + "/website");
        setTimeout(() => {
            setMounted(true);
        }, 1000)
    }, [mounted]);
    if (!mounted) {
        return <div style={{
            display: "grid",
            placeItems: "center",
            alignContent: "center"
        }}>
            <CircularProgress color={isDarkTheme ? "primary" : 'secondary'} />
        </div>
    }
    return (
        <div>
            <Grid container direction="column">
                <Typography variant="h6" sx={{ fontWeight: "bold", color: isDarkTheme ? "#fff" : "#000" }}>
                    {lang === 'eng' && ('Web Application')}
                    {lang === 'idn' && ('Aplikasi Web')}
                    {lang === 'jap' && ('ウェブアプリケーション')}
                </Typography>
                {devPortfolio &&
                    devPortfolio.map((item, index) => (
                        <div key={index} style={{ padding: "0.5rem", marginBottom: '1rem' }}>
                            <Grid item xs={12} md={6}>
                                <Card elevation={0} sx={{ borderRadius: 0 }}>
                                    <CardActionArea
                                        component={Link}
                                        href={item.link}
                                        target="_blank"
                                    >
                                        <CardMedia
                                            component="img"
                                            height="100%"
                                            image={item.img}
                                            alt={item.name}
                                        />
                                        <CardContent>
                                            <Typography variant="h6">{item.name}</Typography>
                                            <Typography variant="body2">
                                                {item.description}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </div>
                    ))}
            </Grid>
        </div>
    )
}
