import { atom } from "recoil";

const darkTheme = atom({
    key: "dark-theme",
    default: true,
});

const language = atom({
    key: "language",
    default: "eng",
});

export { darkTheme, language };
