import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Fade from "react-reveal/Fade";
import "../Pages/Css/home.css";
import Socials from "../Components/Data/Socials";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { darkTheme, language } from "../store";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-206605434-2');

export default function Home() {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(theme.breakpoints.down("lg"));

  const [lang, setLang] = useRecoilState(language);
  const [isDarkTheme, setDarkTheme] = useRecoilState(darkTheme);

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
      ReactGA.pageview(window.location.pathname);
    }, 500)
  }, [mounted]);

  if (!mounted) {
    return <div style={{
      display: "grid",
      placeItems: "center",
      alignContent: "center"
    }}>
      <CircularProgress color={isDarkTheme ? "primary" : 'secondary'} />
    </div>
  }
  return (
    <div
      style={{
        marginTop: matchesMD ? 0 : matchesLG ? "22%" : "12%",
        marginLeft: matchesMD ? 0 : matchesLG ? "9%" : "12%",
        marginRight: matchesMD ? 0 : matchesLG ? "9%" : "12%",
        overflow: "hidden",
      }}
    >
      <Helmet>
        <title>Gusti Bagus Suandana</title>
      </Helmet>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={4}>
          <Fade top>
            <div style={{ marginLeft: "calc(50% - 105px)" }}>
              <div
                className="shape"
                style={{
                  width: "210px",
                  height: "210px",
                  border: "5px solid white",
                  borderRadius: "50%",
                }}
              ></div>
            </div>
          </Fade>
        </Grid>
        <Grid item xs={12} md={8} sx={{ paddingLeft: matchesMD ? 0 : "2rem" }}>
          <Fade right cascade>
            <div>
              {lang === "jap" && (
                <Typography variant="h6"
                  sx={{
                    color: isDarkTheme ? "#fff" : "#000"
                  }}
                >
                  グス千 バグス スアンダナ
                </Typography>
              )}
              <Typography
                variant={matchesMD ? "h4" : "h3"}
                sx={{
                  fontWeight: "bold",
                  marginTop: matchesMD ? "2rem" : 0,
                  color: isDarkTheme ? "#fff" : "#000"
                }}
              >
                GUSTI BAGUS SUANDANA , S.T.
              </Typography>

              <Typography paragraph sx={{
                marginTop: "1rem",
                color: isDarkTheme ? "#fff" : "#000"
              }} align="justify">
                {lang === 'eng' && (
                  "Welcome to my page, it's nice to see you here. Introducing me, Gusti Bagus Suandana, an Architect and Full Stack Developer who is ready to help make your imagination come true. With an engineering background, it is certain that everything will go as expected."
                )}
                {lang === 'idn' && (
                  "Selamat datang di halaman saya, senang bertemu Anda di sini. Perkenalkan saya, Gusti Bagus Suandana, seorang Arsitek dan Full Stack Developer yang siap membantu mewujudkan imajinasi Anda baik dalam arsitektur ataupun aplikasi website. Dengan latar belakang engineering , saya harapkan akan dapat memenuhi harapan anda."
                )}
                {lang === 'jap' && (
                  "私のページへようこそ、ここでお会いできてうれしいです。 私を紹介します。建築家でありフルスタック開発者であるGustiBagus Suandanaは、建築とWebサイトアプリケーションの両方であなたの想像力を実現するのを手伝う準備ができています。 エンジニアリングのバックグラウンドを持っているので、あなたの期待に応えられることを願っています。"
                )}
                <Link to="/about" style={{ textDecoration: 'none', color: 'inherit', marginLeft: '0.5rem', fontWeight: 'bold' }}>
                  {lang === 'eng' && ('more...')}
                  {lang === 'idn' && ('lebih banyak ...')}
                  {lang === 'jap' && ('もっと ...')}
                </Link>
              </Typography>
              <Grid container>
                <Socials />
              </Grid>
            </div>
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
}
