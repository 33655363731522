import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    CircularProgress,
    Grid,
    Link,
    Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { darkTheme, language } from "../../store";

import ReactGA from "react-ga";
import { mobilePortfolio } from "../../Components/Data/MobilePortfolio";
ReactGA.initialize("UA-206605434-2");

export default function MobileApps() {
    const [lang, setLang] = useRecoilState(language);
    const [isDarkTheme, setDarkTheme] = useRecoilState(darkTheme);
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + "/mobile");
        setTimeout(() => {
            setMounted(true);
        }, 1000);
    }, [mounted]);

    if (!mounted) {
        return (
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    alignContent: "center",
                }}
            >
                <CircularProgress
                    color={isDarkTheme ? "primary" : "secondary"}
                />
            </div>
        );
    }
    return (
        <div>
            <Grid container direction="column">
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: "bold",
                        color: isDarkTheme ? "#fff" : "#000",
                    }}
                >
                    {lang === "eng" && "Application"}
                    {lang === "idn" && "Aplikasi"}
                    {lang === "jap" && "アプリケーション"}
                </Typography>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {mobilePortfolio &&
                        mobilePortfolio.map((item, index) => (
                            <div
                                key={index}
                                style={{
                                    padding: "0.5rem",
                                    marginBottom: "1rem",
                                }}
                            >
                                <Grid item xs={12} md={6} lg={5} xl={4}>
                                    <Card
                                        elevation={0}
                                        sx={{ borderRadius: 0 }}
                                    >
                                        <CardActionArea
                                            component={Link}
                                            href={item.link}
                                            target="_blank"
                                        >
                                            <CardMedia
                                                height="auto"
                                                component="img"
                                                image={item.img}
                                                alt={item.name}
                                            />
                                            <CardContent>
                                                <Typography variant="h6">
                                                    {item.name}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {item.description}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            </div>
                        ))}
                </div>
            </Grid>
        </div>
    );
}
