import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Architect from "./Portfolio/Architect";
import MobileApps from "./Portfolio/MobileApps";
import WebApps from "./Portfolio/WebApps";
import { useRecoilState } from "recoil";
import { darkTheme, language } from "../store";
import { CircularProgress } from "@mui/material";

export default function Portfolio() {
    const [lang, setLang] = useRecoilState(language);
    const [isDarkTheme, setDarkTheme] = useRecoilState(darkTheme);
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setMounted(true);
        }, 500);
    }, [mounted]);

    if (!mounted) {
        return (
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    alignContent: "center",
                }}
            >
                <CircularProgress
                    color={isDarkTheme ? "primary" : "secondary"}
                />
            </div>
        );
    }
    return (
        <div style={{ marginBottom: "5rem" }}>
            <Helmet>
                <title>My Works | Gusti Bagus Suandana</title>
            </Helmet>
            <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                            variant="scrollable"
                            scrollButtons="auto"
                            indicatorColor={
                                isDarkTheme ? "primary" : "secondary"
                            }
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                        >
                            <Tab
                                style={{ color: isDarkTheme ? "#fff" : "#000" }}
                                label={
                                    lang === "eng"
                                        ? "Web Apps"
                                        : lang === "idn"
                                        ? "Aplikasi Website"
                                        : "ウェブサイトアプリケーション"
                                }
                                value="1"
                            />
                            <Tab
                                style={{ color: isDarkTheme ? "#fff" : "#000" }}
                                label={
                                    lang === "eng"
                                        ? "Mobile Apps"
                                        : lang === "idn"
                                        ? "Aplikasi Seluler"
                                        : "モバイルアプリ"
                                }
                                value="2"
                            />
                            <Tab
                                style={{ color: isDarkTheme ? "#fff" : "#000" }}
                                label={
                                    lang === "eng"
                                        ? "Architecture"
                                        : lang === "idn"
                                        ? "Arsitektur"
                                        : "建築作品"
                                }
                                value="3"
                            />
                        </TabList>
                    </Box>

                    <TabPanel value="1">
                        <WebApps />
                    </TabPanel>
                    <TabPanel value="2">
                        <MobileApps />
                    </TabPanel>
                    <TabPanel value="3">
                        <Architect />
                    </TabPanel>
                </TabContext>
                {/* <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Item One dolor ipsum" />
          <Tab label="Item Two dolor ipsum" />
          <Tab label="Item Three dolor ipsum" />
        </Tabs> */}
            </Box>
        </div>
    );
}
