export const mobilePortfolio = [
    {
        name: "K2Worshiper",
        link: "https://www.k2worshiper.org",
        img: "./k2w.png",
        description:
            "Web application for management of music players and singers for churches",
    },
    {
        name: "Travelin",
        link: "https://www.k2worshiper.org",
        img: "./ap2.png",
        description:
            "Web application for management of music players and singers for churches",
    },
];
