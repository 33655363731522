import { ArrowForward } from "@mui/icons-material";
import {
    Grid,
    Typography,
    Link,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    IconButton,
    Button,
    useTheme,
    useMediaQuery,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { darkTheme, language } from "../../store";
import { KeyboardArrowDown, KeyboardArrowUp, Info } from "@mui/icons-material";

export default function Architect() {
    const [mounted, setMounted] = useState(false);
    const [posts, setPosts] = useState([]);
    const [meta, setMeta] = useState(""); //meta
    const [metaLinks, setMetaLinks] = useState([]); //meta
    const [pages, setPages] = useState(9);
    const [paginate, setPaginate] = useState("");
    const [search, setSearch] = useState("");
    const [field, setField] = useState("id");
    const [direction, setDirection] = useState("asc");
    useEffect(() => {
        try {
            axios
                .get(
                    `https://visiarch.com/api/posts/data?page=${paginate}&load=${pages}&q=${search}&field=${field}&direction=${direction}`
                )
                .then((res) => {
                    setPosts(res.data.data);
                    setMeta(res.data.meta);
                    setMetaLinks(res.data.meta.links);
                });
        } catch (error) {
            console.log(error);
        }
        setTimeout(() => {
            setMounted(true);
        }, 1500);
    }, [mounted, pages, paginate, search, field, direction]);
    const [lang, setLang] = useRecoilState(language);
    const [isDarkTheme, setDarkTheme] = useRecoilState(darkTheme);
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

    const onChange = (e) => {
        setPages(e.target.value);
    };
    const sort = (item) => {
        setField(item);
        setDirection(direction === "asc" ? "desc" : "asc");
    };

    console.log(posts);
    if (!mounted) {
        return (
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    alignContent: "center",
                }}
            >
                <CircularProgress
                    color={isDarkTheme ? "primary" : "secondary"}
                />
            </div>
        );
    }
    return (
        <div>
            <Typography
                variant="h6"
                sx={{
                    fontWeight: "bold",
                    color: isDarkTheme ? "#fff" : "#000",
                }}
            >
                {lang === "eng" && "Arcitect Portfolio"}
                {lang === "idn" && "Portofolio Arsitektur"}
                {lang === "jap" && "建築家ポートフォリオ"}
            </Typography>
            {/* <Grid container spacing={3}>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel id="pages">Pages</InputLabel>
                        <Select
                            labelId="pages"
                            id="pages"
                            name="pages"
                            value={pages}
                            label="Pages"
                            onChange={onChange}
                        >
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={27}>27</MenuItem>
                            <MenuItem value={64}>64</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        fullWidth
                        label="search"
                        id="q"
                        name="q"
                        value={search}
                        onChange={(e) => { setSearch(e.target.value); setPaginate(1) }}

                    />
                </Grid>
            </Grid> */}
            {/* <Grid container justifyContent="center">
                <div style={{ overflow: "auto", padding: "0.5rem" }}>
                    <div style={{ display: "inline-flex" }}>
                        {metaLinks.map((item, i) => (
                            <div key={i}>
                                <Button
                                    disabled={item.url == null ? true : false}
                                    variant="contained"
                                    size="small"
                                    onClick={() =>
                                        setPaginate(
                                            item.url
                                                ? new URL(
                                                      item.url
                                                  ).searchParams.get("page")
                                                : ""
                                        )
                                    }
                                    color={item.active ? "primary" : "white"}
                                >
                                    {item.label}
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>
            </Grid> */}
            <Grid container direction="column">
                {/* <Grid item>
                    <Grid
                        container
                        direction="row"
                        sx={{ alignItems: "center" }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "bold",
                                color: isDarkTheme ? "#fff" : "#000",
                            }}
                        >
                            {lang === "eng" && "Arcitect Portfolio"}
                            {lang === "idn" && "Portofolio Arsitektur"}
                            {lang === "jap" && "建築家ポートフォリオ"}
                        </Typography>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => sort("title")}
                        >
                            {direction === "asc" ? (
                                <KeyboardArrowUp />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </Button>
                    </Grid>
                </Grid> */}
                <ImageList cols={matchesMD ? 1 : 3}>
                    {posts.map((item) => (
                        <ImageListItem key={item.id}>
                            <img
                                src={`https://www.visiarch.com${item.featuredImage}`}
                                srcSet={`https://www.visiarch.com${item.thumbnail}`}
                                alt={item.title}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                title={item.title}
                                subtitle={item.author.name}
                                actionIcon={
                                    <IconButton
                                        sx={{
                                            color: "rgba(255, 255, 255, 0.54)",
                                        }}
                                        aria-label={`info about ${item.title}`}
                                    >
                                        <Info />
                                    </IconButton>
                                }
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Grid>
        </div>
    );
}
