import { Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { darkTheme } from "../../store";

export default function Copyright() {
    const [isDarkTheme, setDarkTheme] = useRecoilState(darkTheme);
    return (
        <div>
            <Typography
                variant="caption"
                sx={{ color: isDarkTheme ? "#fff" : "#000" }}
            >
                &copy; 2020{" "}
                <a
                    href="https://visiarch.com"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        textDecoration: "none",
                        color: isDarkTheme ? "#fff" : "#000",
                    }}
                >
                    visiarch.com
                </a>
            </Typography>
        </div>
    );
}
