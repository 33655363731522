import { Facebook, GitHub, Instagram, LinkedIn } from "@mui/icons-material";
import { IconButton, Link } from "@mui/material";
import React from "react";
import upwork from "../../Assets/upwork.svg";
import upworkDark from "../../Assets/upwork-icon.svg";
import { useRecoilState } from "recoil";
import { darkTheme } from "../../store";
export default function Socials() {
  const [isDarkTheme, setDarkTheme] = useRecoilState(darkTheme);
  return (
    <div>
      <IconButton color={isDarkTheme ? "white" : "secondary"} name="facebook" component={Link} href="https://www.facebook.com/gustibagus.suandana" target="_blank">
        <Facebook />
      </IconButton>
      <IconButton color={isDarkTheme ? "white" : "secondary"} name="instagram" component={Link} href="https://www.instagram.com/bagussuandana/" target="_blank">
        <Instagram />
      </IconButton>
      <IconButton color={isDarkTheme ? "white" : "secondary"} name="linkedin" component={Link} href="https://www.linkedin.com/in/bagus-suandana/" target="_blank">
        <LinkedIn />
      </IconButton>
      <IconButton component={Link} name="upwork" href="https://www.upwork.com/freelancers/~01b382e0ce47c7158b" target="_blank">
        <img width="24px" height="24px" src={isDarkTheme ? upworkDark : upwork} alt="" />
      </IconButton>
      <IconButton color={isDarkTheme ? "white" : "secondary"} name="github" component={Link} href="https://github.com/bagussuandana" target="_blank">
        <GitHub />
      </IconButton>
    </div>
  );
}
