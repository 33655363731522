import React, { useEffect, useState } from "react";
import {
    Grid,
    LinearProgress,
    Typography,
    useMediaQuery,
    useTheme,
    Stack,
    CircularProgress,
} from "@mui/material";

import { createUseStyles } from "react-jss";
import profile from "../Assets/bagus.png";
import Fade from "react-reveal/Fade";
import Socials from "../Components/Data/Socials";
import { Helmet } from "react-helmet";

import Ind from "../Assets/flag/indonesia.png";
import Eng from "../Assets/flag/united-kingdom.png";
import Jap from "../Assets/flag/japan.png";

import { useRecoilState } from "recoil";
import { darkTheme, language } from "../store";

import ReactGA from "react-ga";
ReactGA.initialize("UA-206605434-2");

const useStyles = createUseStyles({
    biodataContainer: {
        marginBottom: "2rem",
    },
    biodata: {
        marginRight: "1rem",
        fontWeight: "bold",
    },
});

export default function About() {
    const classes = useStyles();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const [lang] = useRecoilState(language);
    const [isDarkTheme] = useRecoilState(darkTheme);

    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setMounted(true);
            ReactGA.pageview(window.location.pathname);
        }, 500);
    }, [mounted]);

    if (!mounted) {
        return (
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    alignContent: "center",
                }}
            >
                <CircularProgress
                    color={isDarkTheme ? "primary" : "secondary"}
                />
            </div>
        );
    }
    return (
        <div style={{ marginBottom: "5rem", overflow: "hidden" }}>
            <Helmet>
                <title>About Me | Gusti Bagus Suandana</title>
            </Helmet>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Fade top>
                        <div
                            style={{
                                height: "400px",
                                width: "100%",
                                border: "5px solid white",
                                borderRadius: "20px",
                                background: `url(${profile})`,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                            }}
                        ></div>
                    </Fade>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ padding: matchesMD ? "2rem 0" : "0 2rem" }}
                >
                    <Fade right cascade>
                        <div>
                            <Typography
                                sx={{
                                    mb: "0.5rem",
                                    color: isDarkTheme ? "#fff" : "#000",
                                }}
                            >
                                <span className={classes.biodata}>
                                    {lang === "eng"
                                        ? "Name"
                                        : lang === "idn"
                                        ? "Nama"
                                        : "名前"}{" "}
                                    :
                                </span>
                                Gusti Bagus Suandana, S.T.
                            </Typography>
                            <Typography
                                sx={{
                                    mb: "0.5rem",
                                    color: isDarkTheme ? "#fff" : "#000",
                                }}
                            >
                                <span className={classes.biodata}>
                                    {lang === "eng"
                                        ? "Nickname"
                                        : lang === "idn"
                                        ? "Nama Panggilan"
                                        : "ニックネーム"}{" "}
                                    :
                                </span>
                                Bagus
                            </Typography>
                            <Typography
                                sx={{
                                    mb: "0.5rem",
                                    color: isDarkTheme ? "#fff" : "#000",
                                }}
                            >
                                <span className={classes.biodata}>
                                    {lang === "eng"
                                        ? "Birthday"
                                        : lang === "idn"
                                        ? "Kelahiran"
                                        : "誕生日"}{" "}
                                    :
                                </span>
                                29.09.1989
                            </Typography>
                            <Grid container direction="row">
                                <Typography
                                    sx={{
                                        mb: "0.5rem",
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    <span className={classes.biodata}>
                                        {lang === "eng"
                                            ? "Language"
                                            : lang === "idn"
                                            ? "Bahasa"
                                            : "言語"}{" "}
                                        :
                                    </span>
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                    <img
                                        style={{
                                            borderRadius: "50%",
                                            border: isDarkTheme
                                                ? "2px solid #fff"
                                                : "2px solid #292929",
                                        }}
                                        width={20}
                                        height={20}
                                        src={Ind}
                                        alt="indonesia"
                                    />
                                    <img
                                        style={{
                                            borderRadius: "50%",
                                            border: isDarkTheme
                                                ? "2px solid #fff"
                                                : "2px solid #292929",
                                        }}
                                        width={20}
                                        height={20}
                                        src={Eng}
                                        alt="english"
                                    />
                                    <img
                                        style={{
                                            borderRadius: "50%",
                                            border: isDarkTheme
                                                ? "2px solid #fff"
                                                : "2px solid #292929",
                                        }}
                                        width={20}
                                        height={20}
                                        src={Jap}
                                        alt="nihon"
                                    />
                                </Stack>
                            </Grid>
                            <Typography
                                sx={{
                                    mb: "0.5rem",
                                    color: isDarkTheme ? "#fff" : "#000",
                                }}
                            >
                                <span className={classes.biodata}>
                                    {lang === "eng"
                                        ? "Address"
                                        : lang === "idn"
                                        ? "Alamat"
                                        : "住所"}{" "}
                                    :
                                </span>
                                Tirtasari - Bali - Indonesia - Asia - Earth
                            </Typography>
                            <Typography
                                sx={{
                                    mb: "0.5rem",
                                    color: isDarkTheme ? "#fff" : "#000",
                                }}
                            >
                                <span className={classes.biodata}>
                                    {lang === "eng"
                                        ? "Email"
                                        : lang === "idn"
                                        ? "Email"
                                        : "Eメール"}{" "}
                                    :
                                </span>
                                visiarch@gmail.com
                            </Typography>
                            <Typography
                                sx={{
                                    mb: "0.5rem",
                                    color: isDarkTheme ? "#fff" : "#000",
                                }}
                            >
                                <span className={classes.biodata}>
                                    {lang === "eng"
                                        ? "Education"
                                        : lang === "idn"
                                        ? "Pendidikan"
                                        : "教育"}{" "}
                                    :
                                </span>
                                {lang === "eng"
                                    ? "Warmadewa University"
                                    : lang === "idn"
                                    ? "Universitas Warmadewa"
                                    : "Warmadewa 大学"}
                            </Typography>
                            <Typography
                                sx={{
                                    mb: "0.5rem",
                                    color: isDarkTheme ? "#fff" : "#000",
                                }}
                            >
                                <span className={classes.biodata}>
                                    {lang === "eng"
                                        ? "Freelance"
                                        : lang === "idn"
                                        ? "Bekerja Lepas"
                                        : "フリーランス"}{" "}
                                    :
                                </span>
                                {lang === "eng"
                                    ? "Available"
                                    : lang === "idn"
                                    ? "Tersedia"
                                    : "利用可能"}
                            </Typography>
                            <Grid container>
                                <Socials />
                            </Grid>
                        </div>
                    </Fade>
                </Grid>
            </Grid>
            <Grid container sx={{ my: "2rem" }}>
                <Fade bottom cascade>
                    <div>
                        <Typography
                            variant="h5"
                            sx={{ color: isDarkTheme ? "#fff" : "#000" }}
                        >
                            {lang === "eng" && "Architect & Apps Developer"}
                            {lang === "idn" && "Arsitek & Pengembang Aplikasi"}
                            {lang === "jap" && "アーキテクトとアプリの開発者"}
                        </Typography>
                        <Typography
                            paragraph
                            sx={{
                                my: "1rem",
                                color: isDarkTheme ? "#fff" : "#000",
                            }}
                            align="justify"
                        >
                            {lang === "eng" &&
                                "I am an Architect (graduated in 2015) and currently working in a large architectural firm in Bali. Over the past 10 years, I have developed various Architectural Designs and Drafting. I have excellent skills in Architectural Design and Drafting. Excellent skills in ArchiCAD and supported by several visualization software such as Cinema 4D, Vray, and Lumion. The thing I like most about architecture is the 3D visualization which is very stunning and of course it supports my work on 3D based application websites."}
                            {lang === "idn" &&
                                "Saya seorang Arsitek (lulus tahun 2015) dan saat ini bekerja di sebuah firma arsitektur besar di Bali. Selama 10 tahun terakhir, saya telah mengembangkan berbagai Desain dan Draft Arsitektur. Saya memiliki keterampilan yang sangat baik dalam Desain Arsitektur dan Drafting. Keterampilan yang sangat baik dalam ArchiCAD dan didukung oleh beberapa perangkat lunak visualisasi seperti Cinema 4D, Vray, dan Lumion. Hal yang paling saya suka dari arsitektur adalah visualisasi 3D yang sangat memukau dan tentunya mendukung pekerjaan saya di website aplikasi berbasis 3D."}
                            {lang === "jap" &&
                                "私は建築家（2015年に卒業）で、現在バリ州の大手建築事務所で働いています。 過去10年間、私はさまざまな建築設計と製図を開発してきました。 私は建築設計と製図の優れたスキルを持っています。 ArchiCADの優れたスキルとCinema4D、Vray、Lumionなどのいくつかの視覚化ソフトウェアによってサポートされています。 アーキテクチャについて私が最も気に入っているのは、3D視覚化です。これは非常に魅力的で、もちろん3DベースのアプリケーションWebサイトでの作業をサポートします。"}
                        </Typography>
                        <Typography
                            paragraph
                            sx={{
                                my: "1rem",
                                color: isDarkTheme ? "#fff" : "#000",
                            }}
                            align="justify"
                        >
                            {lang === "eng" &&
                                "Apart from being an architect, I also have expertise in the field of software engineering. Starting from making an application-based website, I also make mobile applications for Android and iOS. With the help of today's very modern frameworks, all that is possible. I'm very good at Laravel framework backend, and React Js framework frontend, and React Native."}
                            {lang === "idn" &&
                                "Selain sebagai arsitek, saya juga memiliki keahlian di bidang rekayasa perangkat lunak. Mulai dari membuat website berbasis aplikasi, saya juga membuat aplikasi mobile untuk Android dan iOS. Dengan bantuan kerangka kerja yang sangat modern saat ini, semua itu mungkin. Saya sangat baik di backend framework Laravel, dan frontend framework React Js, dan React Native."}
                            {lang === "jap" &&
                                "建築家であるだけでなく、ソフトウェアエンジニアリングの分野でも専門知識を持っています。 アプリケーションベースのWebサイトの作成から始めて、AndroidおよびiOS用のモバイルアプリケーションも作成しています。 今日の非常に近代的なフレームワークの助けを借りて、それはすべて可能です。 私はLaravelフレームワークバックエンド、React Jsフレームワークフロントエンド、およびReactNativeが得意です。"}
                        </Typography>
                        <Typography
                            paragraph
                            sx={{
                                my: "1rem",
                                color: isDarkTheme ? "#fff" : "#000",
                            }}
                            align="justify"
                        >
                            {lang === "eng" &&
                                "Here is some of my background, it would be great if we could talk about your brilliant ideas. I will gladly help make it happen, and will certainly approach your expectations."}
                            {lang === "idn" &&
                                "Berikut adalah beberapa latar belakang saya, alangkah baiknya jika kita bisa membicarakan ide-ide cemerlang Anda. Saya dengan senang hati akan membantu mewujudkannya, dan pasti akan mendekati harapan Anda."}
                            {lang === "jap" &&
                                "これが私の経歴の一部です。あなたの素晴らしいアイデアについて話していただければ幸いです。 私は喜んでそれを実現するのを手伝い、そして確かにあなたの期待に近づきます。"}
                        </Typography>
                    </div>
                </Fade>
            </Grid>
            <Grid container>
                <Grid item md={6} xs={12} sx={{ padding: "1rem" }}>
                    <Fade bottom cascade>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: "bold",
                                    marginBottom: "1rem",
                                    color: isDarkTheme ? "#fff" : "#000",
                                }}
                            >
                                {lang === "eng" && "Architecture Skills"}
                                {lang === "idn" && "Keahlian Arsitektur"}
                                {lang === "jap" && "建築スキル"}
                            </Typography>
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ fontStyle: "italic" }}
                            >
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    Archicad
                                </Typography>
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                    variant="caption"
                                >
                                    90%
                                </Typography>
                            </Grid>
                            <LinearProgress
                                color={isDarkTheme ? "primary" : "secondary"}
                                variant="determinate"
                                value={90}
                            />
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ fontStyle: "italic" }}
                            >
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    Cinema 4d
                                </Typography>
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                    variant="caption"
                                >
                                    50%
                                </Typography>
                            </Grid>
                            <LinearProgress
                                color={isDarkTheme ? "primary" : "secondary"}
                                variant="determinate"
                                value={50}
                            />
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ fontStyle: "italic" }}
                            >
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    Lumion
                                </Typography>
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                    variant="caption"
                                >
                                    80%
                                </Typography>
                            </Grid>
                            <LinearProgress
                                color={isDarkTheme ? "primary" : "secondary"}
                                variant="determinate"
                                value={80}
                            />
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ fontStyle: "italic" }}
                            >
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    Vray
                                </Typography>
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                    variant="caption"
                                >
                                    60%
                                </Typography>
                            </Grid>
                            <LinearProgress
                                color={isDarkTheme ? "primary" : "secondary"}
                                variant="determinate"
                                value={60}
                            />
                        </div>
                    </Fade>
                </Grid>
                <Grid item md={6} xs={12} sx={{ padding: "1rem" }}>
                    <Fade bottom cascade>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: "bold",
                                    marginBottom: "1rem",
                                    color: isDarkTheme ? "#fff" : "#000",
                                }}
                            >
                                {lang === "eng" && "Apps Developer"}
                                {lang === "idn" && "Pengembang Aplikasi"}
                                {lang === "jap" && "アプリ開発者"}
                            </Typography>
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ fontStyle: "italic" }}
                            >
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    Laravel
                                </Typography>
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                    variant="caption"
                                >
                                    90%
                                </Typography>
                            </Grid>
                            <LinearProgress
                                color={isDarkTheme ? "primary" : "secondary"}
                                variant="determinate"
                                value={90}
                            />
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ fontStyle: "italic" }}
                            >
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    Inertia Js
                                </Typography>
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                    variant="caption"
                                >
                                    80%
                                </Typography>
                            </Grid>
                            <LinearProgress
                                color={isDarkTheme ? "primary" : "secondary"}
                                variant="determinate"
                                value={80}
                            />
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ fontStyle: "italic" }}
                            >
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    React Js
                                </Typography>
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                    variant="caption"
                                >
                                    80%
                                </Typography>
                            </Grid>
                            <LinearProgress
                                color={isDarkTheme ? "primary" : "secondary"}
                                variant="determinate"
                                value={80}
                            />
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ fontStyle: "italic" }}
                            >
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    TypeScript
                                </Typography>
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                    variant="caption"
                                >
                                    70%
                                </Typography>
                            </Grid>
                            <LinearProgress
                                color={isDarkTheme ? "primary" : "secondary"}
                                variant="determinate"
                                value={80}
                            />
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ fontStyle: "italic" }}
                            >
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    React Native
                                </Typography>
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                    variant="caption"
                                >
                                    60%
                                </Typography>
                            </Grid>
                            <LinearProgress
                                color={isDarkTheme ? "primary" : "secondary"}
                                variant="determinate"
                                value={60}
                            />
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ fontStyle: "italic" }}
                            >
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    MySQL
                                </Typography>
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                    variant="caption"
                                >
                                    80%
                                </Typography>
                            </Grid>
                            <LinearProgress
                                color={isDarkTheme ? "primary" : "secondary"}
                                variant="determinate"
                                value={60}
                            />
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ fontStyle: "italic" }}
                            >
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                >
                                    Shopify
                                </Typography>
                                <Typography
                                    sx={{
                                        color: isDarkTheme ? "#fff" : "#000",
                                    }}
                                    variant="caption"
                                >
                                    60%
                                </Typography>
                            </Grid>
                            <LinearProgress
                                color={isDarkTheme ? "primary" : "secondary"}
                                variant="determinate"
                                value={60}
                            />
                        </div>
                    </Fade>
                </Grid>
            </Grid>
        </div>
    );
}
