import { EmailOutlined, WhatsApp } from "@mui/icons-material";
import {
  Grid,
  Typography,
  Paper,
  IconButton,
  TextField,
  Button,
  Snackbar,
  Dialog,
  DialogContent,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import Socials from "../Components/Data/Socials";
// function captchaChange(value) {
//   console.log("Captcha value:", value);
// }
import { useRecoilState } from "recoil";
import { darkTheme, language } from "../store";
require("dotenv").config();

export default function Contact() {
  const [lang, setLang] = useRecoilState(language);
  const [isDarkTheme, setDarkTheme] = useRecoilState(darkTheme);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneHelper, setPhoneHelper] = useState("");
  const [message, setMessage] = useState("");

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: "", backgroundColor: "" })

  const [captcha, setCaptcha] = useState(false)

  const recaptchaKey = process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST
    : process.env.REACT_APP_RECAPTCHA_SITE_KEY
  const messagePost = process.env.NODE_ENV === "development"
    ? 'http://localhost:8000/api/message/store'
    : 'https://www.visiarch.com/api/message/store'

  const captchaChange = () => {
    setCaptcha(true)
  }
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const onChange = (event) => {
    let valid;
    switch (event.target.id) {
      case "email":
        setEmail(event.target.value);
        valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          event.target.value
        );
        if (!valid) {
          setEmailHelper("Invalid Email");
        } else {
          setEmailHelper("");
        }

        break;

      case "phone":
        setPhone(event.target.value);
        valid = /^[0-9-+\s]+$/.test(event.target.value);
        if (!valid) {
          setPhoneHelper("Invalid Phone");
        } else {
          setPhoneHelper("");
        }
        break;
      default:
        break;
    }
  };
  const apps = 'profile'
  const credentials = { name, email, phone, message, apps }

  const onConfirm = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios.post(messagePost, credentials)
      .then(function (response) {
        setLoading(false);
        setOpen(false);
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setAlert({ open: true, message: response.data.message, backgroundColor: "#4BB543" });
        setCaptcha(false);
      })
      .catch(function (e) {
        // handle error
        setLoading(false);
        setAlert({ open: true, message: e.response.data.message, backgroundColor: "#FF3232" });
        setCaptcha(false);
      });
  };

  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 500)
  }, [mounted]);

  if (!mounted) {
    return <div style={{
      display: "grid",
      placeItems: "center",
      alignContent: "center"
    }}>
      <CircularProgress color={isDarkTheme ? "primary" : 'secondary'} />
    </div>
  }
  return (
    <div style={{ marginBottom: "5rem" }}>
      <Helmet>
        <title>Reach Me | Gusti Bagus Suandana</title>
      </Helmet>
      <Grid container direction="row">
        <Grid item xs={12} md={6}>
          <Grid container direction="column">
            <Paper
              elevation={0}
              sx={{ borderRadius: 0, padding: "1rem", maxWidth: "500px", bgcolor: isDarkTheme ? '#eeeeee' : '#fff' }}
            >
              <Grid item>
                <Typography variant="h4">
                  {lang === 'eng' && ("Reach Me")}
                  {lang === 'idn' && ("Hubungi Saya")}
                  {lang === 'jap' && ("私に連絡して")}
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: "1rem" }}>
                <Grid container direction="column" justifyContent="flex-start">
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      color="secondary"
                      component="a"
                      href="https://wa.me/6281909234889?text=I'm%20interested%20in%20your%20service"
                      target="_blank"
                    >
                      <WhatsApp />
                    </IconButton>
                    <Typography>+6281 909 234 889</Typography>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      color="secondary"
                      component="a"
                      href="mailto:visiarch@gmail.com"
                      target="_blank"
                    >
                      <EmailOutlined />
                    </IconButton>

                    <Typography>visiarch@gmail.com</Typography>
                  </div>
                </Grid>
              </Grid>
            </Paper>
            <Paper
              elevation={0}
              sx={{
                bgcolor: isDarkTheme ? '#eeeeee' : '#fff',
                borderRadius: 0,
                padding: "1rem",
                marginTop: "0.5rem",
                maxWidth: "500px",
              }}
            >
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                label={lang === "jap" ? "名前" : "Name"}
                variant="outlined"
              />
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="email"
                value={email}
                onChange={onChange}
                error={emailHelper.length !== 0}
                helperText={emailHelper}
                label={lang === "jap" ? "Eメール" : "Email"}
                variant="outlined"
              />
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="phone"
                value={phone}
                onChange={onChange}
                helperText={phoneHelper}
                error={phoneHelper.length !== 0}
                label={lang === "jap" ? "電話" : "Phone"}
                variant="outlined"
              />
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="message"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                label={lang === "jap" ? "メッセージ" : "Message"}
                multiline
                variant="outlined"
                rows={8}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpen(true)}
                disabled={
                  name.length === 0 ||
                  message.length === 0 ||
                  phoneHelper.length !== 0 ||
                  emailHelper.length !== 0
                }
                sx={{ borderRadius: 0 }}
              >
                {lang === 'eng' && ("Send")}
                {lang === 'idn' && ("Kirim")}
                {lang === 'jap' && ("送信")}
              </Button>
            </Paper>
            <div style={{ marginTop: '1rem' }}>
              <Socials />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            borderRadius: 0,
            padding: matchesMD ? 0 : "2rem",
          },
        }}
      >
        <DialogContent>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
                {lang === "eng" && ('Confirm Message')}
                {lang === "idn" && ('Konfirmasi Pesan')}
                {lang === "jap" && ('メッセージの確認')}
              </Typography>
            </Grid>
            <Grid item container>
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                label={lang === "jap" ? "名前" : "Name"}
                variant="outlined"
              />
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="email"
                value={email}
                onChange={onChange}
                error={emailHelper.length !== 0}
                helperText={emailHelper}
                label={lang === "jap" ? "Eメール" : "Email"}
                variant="outlined"
              />
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="phone"
                value={phone}
                onChange={onChange}
                helperText={phoneHelper}
                error={phoneHelper.length !== 0}
                label={lang === "jap" ? "電話" : "Phone"}
                variant="outlined"
              />
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="message"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                label={lang === "jap" ? "メッセージ" : "Message"}
                multiline
                variant="outlined"
                rows={8}
              />
            </Grid>
          </Grid>
          <Grid item>
            <ReCAPTCHA
              sitekey={recaptchaKey}
              onChange={captchaChange}
            />
          </Grid>
          <Grid item sx={{ marginTop: '0.5rem' }}>
            <Grid container direction="row">
              <Button
                variant="contained"
                color="secondary"
                disabled={
                  name.length === 0 ||
                  message.length === 0 ||
                  phoneHelper.length !== 0 ||
                  emailHelper.length !== 0 || captcha === false
                }
                sx={{ borderRadius: 0, minWidth: "89px" }}
                onClick={onConfirm}
              >
                {loading ? <CircularProgress size={29} /> : lang === 'jap' ? "送信" : lang === "eng" ? "Send" : "Kirim"}
              </Button>
              <Button
                variant="outlined"
                sx={{ borderRadius: 0, minWidth: "89px" }}
                color="secondary"
                onClick={() => { setOpen(false); setCaptcha(false) }}
              >
                {lang === 'eng' && ('Cancel')}
                {lang === 'idn' && ('Batal')}
                {lang === 'jap' && ('キャンセル')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={alert.open}
        onClose={() => setAlert({ open: false })}
        message={alert.message}
        ContentProps={{
          style: {
            backgroundColor: alert.backgroundColor
          }
        }} anchorOrigin={{ vertical: "top", horizontal: "left" }} autoHideDuration={3000} />
    </div>
  );
}
