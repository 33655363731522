class CustomLog {
  log(
    header = "Berhenti !!!",
    body = "Ini adalah fitur khusus untuk web developer. Jika anda salah satunya silahkan melanjutkan penelitian anda, jika tidak segera tutup fitur ini",
    footer = "Penting ! : Jika seseorang meminta Anda untuk menyalin-menempel sesuatu di sini , ini adalah penipuan dan akan membahayakan akun anda."
  ) {
    console.log(
      `%c${header}`,
      `color: red; font-weight: bold; font-size: 2rem;`
    );
    console.log(
      `%c${body}`,
      `color: grey; font-weight: semi-bold; font-size: 1.5rem;`
    );
    console.log(
      `%c${footer}`,
      `color: red; font-weight: bold; font-size: 1rem;`
    );
  }
}

export default CustomLog;
